export interface ICallOptions {
    maxRetries?: number;
    noRetry?: boolean;
}

const DEFAULT_CALL_OPTIONS: ICallOptions = {
    maxRetries: 3,
    noRetry: true
};

type METHOD = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export class ApiClient {

    constructor(private baseUrl: string, public accessToken?: string) { }

    private getHttpOptions(method: METHOD, data?: any): RequestInit {
        const headers: Record<string, string> = {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };
        if (this.accessToken) {
            headers.Authorization = `Bearer ${this.accessToken}`;
        }
        return {
            method,
            headers,
            body: !!data ? JSON.stringify(data) : undefined
        };
    }

    private getEffectiveUrl(url: string): string {
        if (!url && !this.baseUrl)
            throw new Error("URL of endpoint is invalid");

        if (url.indexOf('http://') === 0 || url.indexOf('https://') === 0)
            return url;

        if (!this.baseUrl)
            return url;

        let effectiveUrl = this.baseUrl;
        if (effectiveUrl[effectiveUrl.length - 1] !== '/')
            effectiveUrl += '/';
        if (url[0] === '/') {
            url = url.substring(1);
        }
        effectiveUrl += url;
        return effectiveUrl;
    }

    private async fetchRetry<TBody, TResponse>(url: string, method: METHOD, data?: TBody, callOptions?: ICallOptions): Promise<TResponse> {
        const effectiveUrl = this.getEffectiveUrl(url);

        const { noRetry, maxRetries } = callOptions || DEFAULT_CALL_OPTIONS;
        const httpOptions = this.getHttpOptions(method, data);
        if (noRetry) {
            try {
                console.debug(`Issuing ${method} call to ${effectiveUrl}...`);
                const response = await fetch(effectiveUrl, httpOptions);
                if (!response.ok) {
                    throw new Error(`HTTP Error code: ${response.status} - Message: ${response.statusText}`);
                }
                console.debug(`${method} ${effectiveUrl} succeeded`);
                try {
                    return (await response.json()) as TResponse;
                } catch (error) {
                    try {
                        return (await response.text()) as TResponse;
                    } catch {
                        return undefined;
                    }
                }
            } catch (error) {
                throw new Error(`An error occured while trying to issue HTTP call ${JSON.stringify(error)}`);
            }

        } else {
            let tryCount = 0;
            const errors = [];
            do {
                try {
                    console.debug(`Issuing ${method} call to ${effectiveUrl}... - Attempt #${tryCount + 1}`);
                    const response = await fetch(effectiveUrl, httpOptions);
                    if (response.ok) {
                        try {
                            return (await response.json()) as TResponse;
                        } catch (error) {
                            try {
                                return (await response.text()) as TResponse;
                            } catch {
                                return undefined;
                            }
                        }
                    } else {
                        console.error(`HTTP Error code: ${response.status} - Message: ${response.statusText}`);
                    }
                    console.debug(`${method} ${effectiveUrl} - Attempt #${tryCount + 1}  failed!`);
                    tryCount++;
                } catch (error) {
                    errors.push(error);
                    console.error('An error occured while trying to issue HTTP call', error);
                }
            } while (tryCount < maxRetries);

            throw new Error(`An error occured while trying to issue HTTP call after ${maxRetries} - ${JSON.stringify(errors)}`);
        }
    }

    async get<T>(url: string, callOptions?: ICallOptions): Promise<T> {
        return await this.fetchRetry(url, 'GET', null, callOptions);
    }

    async post<TBody, TResponse>(url: string, data?: TBody, callOptions?: ICallOptions): Promise<TResponse> {
        return await this.fetchRetry(url, 'POST', data, callOptions);
    }

    async put<TBody, TResponse>(url: string, data?: TBody, callOptions?: ICallOptions): Promise<TResponse> {
        return await this.fetchRetry(url, 'PUT', data, callOptions);
    }

    async patch<TBody, TResponse>(url: string, data?: TBody, callOptions?: ICallOptions): Promise<TResponse> {
        return await this.fetchRetry(url, 'PATCH', data, callOptions);
    }

    async delete(url: string, callOptions?: ICallOptions): Promise<void> {
        return await this.fetchRetry(url, 'DELETE', null, callOptions);
    }
}