import mainLogo from '../../assets/images/main-logo.png';
import { useMsal } from "@azure/msal-react"
import { useCallback } from "react";
import { api_scope } from "../../auth/authConfig";
import { Button, Stack } from '@mui/material';
import './Welcome.css';
import getConfig from "../../config/config";

const config = getConfig('PROD');

export const Welcome = () => {
    const { instance } = useMsal();

    const signIn = useCallback(async () => {
        await instance.loginRedirect({
            redirectUri: config.app.redirectUri,
            scopes: [api_scope]
        });
    }, [instance]);

    // const buttons: Button[] = [
    //   <Button label='Connectez-vous' onClick={() => signIn()} variant='primary'  />,
    // ];

    return <div className="">
      <Stack direction='column' justifyContent='center' alignContent='center' alignItems='center' style={{height:'85vh'}}  >
        {/* <div className="WelcomeText"> */}
            <img className="Logo" src={mainLogo} alt="TeamsRide logo" />
            {/* <button onClick={() => signIn()}>Connectez-vous</button> */}
            
                <Button onClick={() => signIn()} variant='contained' color='primary'>Connectez-vous </Button>
            </Stack>
        {/* </div> */}
    </div>
}