import { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUserContext } from "../../contexts/UserContext";
import { useTeamContext } from "../../contexts/TeamContext";
import { Avatar, Button, MenuItem, Select, Stack, TextField } from "@mui/material";
import { IPassenger } from "../../models/IPassenger";
import profileIcon from '../../assets/icons/ProfileIcon.svg';
import { IRide } from "../../models/IRide";
import './BookEventRide.css';
import { useDialogs } from "../common/Dialog/DialogProvider";
import { useAppContext } from "../../contexts/AppContext";

export interface IPassengerProps {
    passenger: IPassenger;
    selected?: boolean;
    onToggleSelect?: (passenger: IPassenger) => void;
}

export const Passenger = ({ passenger, selected, onToggleSelect: onSelected }: IPassengerProps) => {
    return <div className={`passenger ${(selected ? 'selected' : '')}`}
        key={`passenger_${passenger.id}`}
        onClick={() => onSelected(passenger)}>
        <Stack direction='row' alignContent='center' alignItems='center' spacing={3}>
            <Avatar src={passenger.avatar || profileIcon} />
            <span>{passenger.name}</span>
        </Stack>
    </div>;
}

const getRemainingSeatsOnRide = (ride: IRide) => {
    if (!ride)
        return 0;

    const remainingSeats = ride.availableSeats - ((ride?.bookings?.length) || 0);
    return remainingSeats;
};

const Ride = (ride: IRide) => {
    let { name } = ride;
    const remainingSeats = getRemainingSeatsOnRide(ride);
    return `${name} - ${remainingSeats} places disponible${(remainingSeats > 1 ? 's' : '')}`;
};

const MAX_PASSENGERS_SELECTION = 2;

export const BookEventRide = () => {
    const navigate = useNavigate();
    const { displayMessage } = useAppContext();
    const { confirm } = useDialogs();
    const { eventId } = useParams<{ eventId: string }>();
    const { eventsAndRides, bookRide, teamId } = useTeamContext();
    const { user, addPassengersToCurrentUser } = useUserContext();
    const [rideId, setRideId] = useState<string>(null);
    const [comments, setComments] = useState<string>('');
    const [selectedPassengers, setSelectedPassengers] = useState<string[]>([]);
    const event = useMemo(() => eventsAndRides.find(e => e.event.id === eventId), [eventsAndRides, eventId]);
    const selectedRide = useMemo<IRide>(() => !!rideId && event?.rides.find(cp => cp.id === rideId), [event, rideId]);
    const availableRides = useMemo(() => event?.rides.filter(cp => getRemainingSeatsOnRide(cp) > 0), [event]);
    const [newPassengerName, setNewPassengerName] = useState<string>('');
    const addPassenger = useCallback(async () => {
        await addPassengersToCurrentUser([{ name: newPassengerName } as IPassenger]);
        setNewPassengerName('');
    }, [newPassengerName, addPassengersToCurrentUser, setNewPassengerName]);


    const canValidateBooking = useMemo(() => !!rideId && !!event?.event && !!selectedPassengers.length, [event, rideId, selectedPassengers]);
    const validateBooking = useCallback(async () => {

        if (!canValidateBooking)
            return;

        try {
            await bookRide(event.event.id, rideId, selectedPassengers, comments);
            navigate(`/team/${teamId}/events`);
        } catch (e) {
            displayMessage('La réservation ne peut pas être réalisée.. Veuillez réessayer ultérieurement...', 'error');
        }
    }, [canValidateBooking, selectedPassengers, navigate, bookRide, displayMessage, event, teamId, rideId, comments]);

    const onTogglePassengerSelected = useCallback((passenger: IPassenger) => {
        if (selectedPassengers.indexOf(passenger.id) < 0) {
            if (selectedPassengers.length >= MAX_PASSENGERS_SELECTION)
                return;

            if (selectedRide) {
                const remaining = getRemainingSeatsOnRide(selectedRide) - selectedPassengers.length;
                if (remaining === 0)
                    return;
            }

            setSelectedPassengers(prev => [...prev, passenger.id]);
        } else {
            setSelectedPassengers(prev => prev.filter(p => p !== passenger.id));
        }
    }, [selectedRide, selectedPassengers, setSelectedPassengers]);

    const cancel = useCallback(async () => {
        if (await confirm(<>
            <p>{`Souhaitez-vous vraimer annuler cette réservation ? `}</p></>,
            'Oui', 'Non')) {
            navigate(-1);
        }
    }, [navigate, confirm]);

    if (!event?.event)
        return null;

    return <div className="RideBooking">
        <h1>Réserver un Ride pour {event.event.name}  </h1>
        <Stack direction={'column'} spacing={3}>
            <Select
                value={rideId}
                label="Voiture"
                onChange={ev => setRideId(ev.target.value)}
            >
                {availableRides.map(cp => <MenuItem value={cp.id}>{Ride(cp)}</MenuItem>)}
            </Select>
            <h2>Passagers: ({MAX_PASSENGERS_SELECTION} max.)</h2>
            <Stack direction={'column'} alignContent='center' spacing={1}>
                {user.responsibleOf?.map(p => <Passenger passenger={p}
                    selected={selectedPassengers.indexOf(p.id) > -1}
                    onToggleSelect={onTogglePassengerSelected} />)}
            </Stack>

            <Stack direction={'row'} alignItems='center' alignContent='center' spacing={3}>
                <TextField label='Nouveau passager' autoComplete="off" variant="outlined" value={newPassengerName} onChange={ev => setNewPassengerName(ev.target.value)} />
                <Button variant="outlined" onClick={() => addPassenger()}>Ajouter</Button>
            </Stack>
            <TextField label='Commentaire'
                value={comments}
                multiline
                rows={5}
                onChange={ev => setComments(ev.target.value)} />
            <Button variant="contained" color="primary" disabled={!canValidateBooking} onClick={() => validateBooking()}>Valider</Button>
            <Button variant="outlined" color="secondary" onClick={() => cancel()}>Annuler</Button>
        </Stack>
    </div>;
}