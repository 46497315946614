const PROD_CONFIG = {
    "api": {
        "baseUrl": "https://api.teamsride.app/"
    },
    "app": {
        "redirectUri": "https://teamsride.app/authRedirect"
        // "redirectUri": "http://localhost:3000/authRedirect"
    }
};

const DEV_CONFIG = {
    "api": {
        "baseUrl": "https://localhost:7158/"
    },
    "app": {
        "redirectUri": "http://localhost:3000/authRedirect"
    }
};

const getConfig = (ENV: 'DEV' | 'TEST' | 'PROD') => {
    switch (ENV) {
        case 'DEV':
            return DEV_CONFIG;
        case 'TEST':
        case 'PROD':
            // return DEV_CONFIG;
            return PROD_CONFIG;
    }
};

export default getConfig;