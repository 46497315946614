import { IPublicClientApplication } from "@azure/msal-browser";
import { AccessTokenBasedService } from "./AccessTokenBasedService";
import { INotification } from "../models/INotification";

export class NotificationsService extends AccessTokenBasedService {

    constructor(public msalInstance: IPublicClientApplication) {
        super(msalInstance)
    }

    async getMyNotifications(): Promise<INotification[]> {
        try {
            const client = await this.getClient();
            return await client.get(`/api/notifications`);
        } catch (error) {
            console.error('Error while trying to get notifications');
            return [];
        }
    }

    async readNotifications(notificationIds: string[]) : Promise<void> {
        try {
            const client = await this.getClient();
            return await client.post(`/api/notifications/read`, {notificationIds});
        } catch (error) {
            console.error('Error while trying to read notifications');
        }
    }
}