import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RidesService } from "../services/RidesService";
import { IEventAndRides } from "../models/IEventAndRides";
import { IRide } from "../models/IRide";
import { UserService } from "../services/UserService";
import { useMsal } from "@azure/msal-react";
import { ITeam } from "../models/ITeam";
import { useUserContext } from "./UserContext";
import { TeamsService } from "../services/TeamsService";
import { ITeamMembership } from "../models/ITeamMembership";

export interface ITeamContext {
    teamId: string;
    teamName: string;
    team?: ITeam;
    isLoading: boolean;
    eventsAndRides: IEventAndRides[];
    currentUserIsAdmin: boolean;
    getMembers: () => Promise<ITeamMembership[]>;
    updateTeam: (team: ITeam) => void;
    regenerateJoinCode: () => Promise<string>;
    applyAsDriver: (eventId: string, ride: IRide) => Promise<void>;
    bookRide: (eventId: string, rideId: string, passengerIds: string[], comments?: string) => Promise<void>;
}

const defaultContext: ITeamContext = {
    teamId: '',
    teamName: '',
    team: null,
    isLoading: false,
    eventsAndRides: [],
    currentUserIsAdmin: false,
    updateTeam: () => { },
    regenerateJoinCode: async () => '',
    applyAsDriver: async () => { },
    bookRide: async () => { },
    getMembers: async () => []
};

const TeamContext = createContext<ITeamContext>(defaultContext);


export interface ITeamContextProviderProps {
    children: any;
    teamId: string;
}

export const useTeamContext = () => useContext(TeamContext);



export const TeamContextProvider = (props: ITeamContextProviderProps) => {
    const { instance } = useMsal();
    const { selectedTeamId } = useUserContext();
    const [refreshStamp, setRefreshStamp] = useState<number>(null);
    const teamId = props.teamId || selectedTeamId;
    const userService = useMemo(() => new UserService(instance), [instance]);
    const ridesService = useMemo(() => !!teamId && new RidesService(instance, teamId), [instance, teamId]);
    const teamsService = useMemo(() => new TeamsService(instance), [instance]);

    const applyAsDriver = useCallback(async (eventId: string, ride: IRide) => {
        if (ridesService) {
            await ridesService.applyAsDriver(teamId, eventId, ride);
            setRefreshStamp(+new Date());
        }
    }, [ridesService, teamId, setRefreshStamp]);

    const bookRide = useCallback(async (eventId: string, rideId: string, passengerIds: string[], comments?: string) => {
        if (ridesService) {
            await ridesService.bookRide(teamId, eventId, rideId, passengerIds, comments);
            setRefreshStamp(+new Date());
        }
    }, [ridesService, teamId, setRefreshStamp]);

    const updateTeam = useCallback(async (team: ITeam) => {
        await teamsService.updateTeam(team);
        setRefreshStamp(+new Date());
    }, [teamsService, setRefreshStamp]);

    const getMembers = useCallback(async () => {
        if (!teamId)
            return [];

        return await teamsService.getMembers(teamId);
    }, [teamId, teamsService]);

    const regenerateJoinCode = useCallback(async () => {
        if (!teamId)
            throw new Error('Team Id is not set, cannot regenerate join code!');
        const newCode = (await teamsService?.regenerateJoinCode(teamId)) || '';
        return newCode;
    }, [teamsService, teamId]);

    const [context, setContext] = useState<ITeamContext>({
        ...defaultContext,
        applyAsDriver: applyAsDriver,
        bookRide: bookRide,
        teamId,
        getMembers,
        updateTeam,
        regenerateJoinCode
    });


    const loadTeam = useCallback(async () => {
        try {
            setContext(prev => ({ ...prev, isLoading: true }));
            const eventsAndCarRides = ridesService ? await ridesService.getUpcomingEvents() : [];
            const myTeams = await await userService.getMyTeams();
            const currentMembership = myTeams.find(tm => tm.teamId === teamId);
            const team = currentMembership?.team;
            const currentUserIsAdmin = currentMembership?.isAdmin || false;
            const teamName = team?.name;
            setContext(prev => ({
                ...prev,
                eventsAndRides: eventsAndCarRides,
                team,
                teamName,
                currentUserIsAdmin
            }));
        } catch (error) {
            console.error("Could not load team");
        }
        finally {
            setContext(prev => ({ ...prev, isLoading: false }));
        }
    }, [ridesService, userService, teamId]);

    useEffect(() => {
        loadTeam();
    }, [loadTeam, refreshStamp]);

    return <TeamContext.Provider value={context}>
        {props.children}
    </TeamContext.Provider>
}