import { useCallback, useMemo } from "react";
import { useTeamContext } from "../../contexts/TeamContext";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useUserContext } from "../../contexts/UserContext";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";
import { Welcome } from "../Welcome/Welcome";
import { NavBar } from "../Navbar/NavBar";
import { Notifications } from "../Notifications/Notifications";
import balanceIcon from '../../assets/images/balance-icon.png';
import { useEventContext } from "../../contexts/EventContext";
import { Stack } from "@mui/material";
import { getFriendlyDate } from "../../helpers/date-helpers";

const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isAuthenticated = useIsAuthenticated();
    const { selectTeam, user } = useUserContext();
    const { teamName, teamId } = useTeamContext();
    const { event } = useEventContext();
    const balance = useMemo(() => {
        if (!teamId || !user?.teamMemberships || Object.keys(user.teamMemberships).length === 0)
            return 0;

        return user.teamMemberships[teamId]?.balance;
    }, [user, teamId]);

    const changeTeam = useCallback(() => {
        selectTeam(null);
        navigate('/select-team');
    }, [selectTeam, navigate]);

    const headerTitle = useMemo(() => {
        const route = location.pathname.toLowerCase();
        if (!!event) {
            if (route.endsWith('/book')) {
                return <Stack direction={'row'} gap={2} spacing={2}>
                    <svg onClick={() => navigate(-1)} className="BackButton" width="12" height="21" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.0742 20.2559L0.318359 10.5L10.0742 0.744141L11.2559 1.92578L2.68164 10.5L11.2559 19.0742L10.0742 20.2559Z" fill="white" />
                    </svg>
                    {event.name} - {getFriendlyDate(event.startTime)} <br />
                    Réserver un Ride
                </Stack >;

            } else if (route.endsWith('/applyasdriver')) {
                return <Stack direction={'row'} gap={2} spacing={2}>
                    <svg onClick={() => navigate(-1)} className="BackButton" width="12" height="21" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.0742 20.2559L0.318359 10.5L10.0742 0.744141L11.2559 1.92578L2.68164 10.5L11.2559 19.0742L10.0742 20.2559Z" fill="white" />
                    </svg>
                    {event.name} - {getFriendlyDate(event.startTime)} <br />
                    Proposer un Ride
                </Stack>;
            } else {
                return <Stack direction={'row'} gap={2} spacing={2}>
                    <svg onClick={() => navigate(-1)} className="BackButton" width="12" height="21" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.0742 20.2559L0.318359 10.5L10.0742 0.744141L11.2559 1.92578L2.68164 10.5L11.2559 19.0742L10.0742 20.2559Z" fill="white" />
                    </svg>
                    Evênement<br />
                    {event.name} - {getFriendlyDate(event.startTime)}
                </Stack>;
            }
        }

        if (teamName) {
            return <div>{teamName}<br />Evenements</div>;
        }

        switch (route) {
            case '/profile':
                return 'Profil';
            case '/my-team':
                return 'Equipe';
            default:
                return '';
        }
    }, [teamName, location, event, navigate]);

    if (!isAuthenticated)
        return null;

    return <header className="AppHeader">
        <div onClick={() => changeTeam()}>
            {headerTitle}
        </div>
        <div className="Quota">
            <img className="QuotaCar" src={balanceIcon} alt="Car Quota" />
            {balance} X
        </div>
        <div className="Notification">
            <Notifications />
        </div>
    </header>;
};

const Footer = () => {
    const isAuthenticated = useIsAuthenticated();
    if (!isAuthenticated)
        return null;

    return <footer className="AppFooter">
        <NavBar />
    </footer>;
}

export const AppLayout = () => {
    return <div className="AppLayout">
        <Header />
        <div className="spacing"></div>
        <AuthenticatedTemplate>
            <Outlet />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            <Welcome />
        </UnauthenticatedTemplate>
        <div className="spacing"></div>
        <Footer />
    </div>
};