import { getMonthPeriodFriendly } from "../../helpers/date-helpers";
import { IMonthAndYear } from "../../models/IMonthAndYear";
import { useCallback, useMemo } from "react";

export interface IMonthSelectorProps {
    minPeriod: IMonthAndYear;
    maxPeriod: IMonthAndYear;
    selectedPeriod: IMonthAndYear;
    setSelectedPeriod: (period: IMonthAndYear) => void;
}

export const MonthSelector = (props: IMonthSelectorProps) => {
    const { selectedPeriod, setSelectedPeriod, maxPeriod, minPeriod } = props;

    const previousMonth = useMemo(() => selectedPeriod.month === 0
        ? { month: 11, year: selectedPeriod.year - 1 }
        : { month: selectedPeriod.month - 1, year: selectedPeriod.year }, [selectedPeriod]);
    const canGoPrevious = useMemo(() => {
        console.log('minPeriod', minPeriod);
        console.log('previousMonth', previousMonth);
        if (!!minPeriod)
            return !(minPeriod.year >= previousMonth.year && minPeriod.month >= previousMonth.month);
        return true;
    }, [minPeriod, previousMonth]);
    const goToPreviousMonth = useCallback(() => {
        if (!canGoPrevious)
            return;

        setSelectedPeriod(previousMonth);
    }, [previousMonth, setSelectedPeriod, canGoPrevious]);

    const nextMonth = useMemo(() => selectedPeriod.month === 11
        ? { month: 0, year: selectedPeriod.year + 1 }
        : { month: selectedPeriod.month + 1, year: selectedPeriod.year }, [selectedPeriod]);
    const canGoNext = useMemo(() => {
        if (!!maxPeriod)
            return !(maxPeriod.year <= nextMonth.year && maxPeriod.month <= nextMonth.month);

        return true;
    }, [maxPeriod, nextMonth]);
    const goToNextMonth = useCallback(() => {
        if (!canGoNext)
            return;

        setSelectedPeriod(nextMonth);
    }, [nextMonth, setSelectedPeriod, canGoNext]);

    return <div className="MonthSelect">
        {canGoPrevious && <span onClick={() => goToPreviousMonth()}>
            <svg className="ChevronLeft" width="12" height="21" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.0742 20.2559L0.318359 10.5L10.0742 0.744141L11.2559 1.92578L2.68164 10.5L11.2559 19.0742L10.0742 20.2559Z" fill="#333333" />
            </svg>
        </span>}
        <div className="Month"> {getMonthPeriodFriendly(new Date(selectedPeriod.year, selectedPeriod.month, 1))} </div>
        {canGoNext && <span onClick={() => goToNextMonth()}>
            <svg className="ChevronRight" width="12" height="21" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.4375 0.744141L11.1934 10.5L1.4375 20.2559L0.255859 19.0742L8.83008 10.5L0.255859 1.92578L1.4375 0.744141Z" fill="#333333" />
            </svg>
        </span>}
    </div>;
}