import { useParams } from "react-router-dom";
import { TeamContextProvider } from "../../contexts/TeamContext";
import { AppLayout } from "./Layout";
import { EventContextProvider } from "../../contexts/EventContext";

export const EventContextLayout = () => {
    const { eventId, teamId } = useParams();
    return <TeamContextProvider teamId={teamId}>
        <EventContextProvider eventId={eventId}>
            <AppLayout />
        </EventContextProvider>;
    </TeamContextProvider>
};