import { useEffect, useRef } from "react";
import { Loading } from "../Loading/Loading";
import { useUserContext } from "../../contexts/UserContext";
import { useNavigate } from "react-router-dom";

export const Onboarding = () => {
    const navigate = useNavigate();
    const { user, onboard } = useUserContext();
    const ongoingRef = useRef<boolean>(false);
    useEffect(() => {
        if (!user.isNewcomer)
            return;

        if (ongoingRef.current)
            return;


        ongoingRef.current = true;
        onboard()
            .then(() => {
                localStorage.setItem('TeamsRide_onboarded', 'done');
                navigate('/');
            })
            .catch(() => navigate('/'))
            .finally(() => {
                ongoingRef.current = false;
            });
    }, [user, onboard, navigate]);

    return <Loading message='Bienvenue sur TeamsRide, un instant svp...' />;
};