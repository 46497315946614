import { useCallback, useEffect, useMemo, useState } from "react"
import { IEventAndRides } from "../../models/IEventAndRides"
import { EventCard } from "../EventCard/EventCard";
import { addMonths } from "date-fns";
import { useTeamContext } from "../../contexts/TeamContext";
import './EventsList.css';
import { MonthSelector } from "../MonthSelector/MonthSelector";
import { IMonthAndYear } from "../../models/IMonthAndYear";
import { getMonthPeriodFriendly, isUpcoming } from "../../helpers/date-helpers";
import { Loading } from "../Loading/Loading";



export interface IEventCardsGroupProps {
  periodFirstDay: Date;
  events: IEventAndRides[];
}

export function EventCardsGroup(props: IEventCardsGroupProps) {
  const { events, periodFirstDay } = props;

  return <div id={`period_${periodFirstDay.getFullYear()}_${periodFirstDay.getMonth()}`}>
    <h2 className="Period">{getMonthPeriodFriendly(periodFirstDay)}</h2>
    {!events || events.length === 0
      ? <span className="NoEvent">Aucun événement disponible</span>
      : events.map(ev => <EventCard eventData={ev} />)}
  </div>;
}

export function EventsList() {
  const { isLoading, eventsAndRides } = useTeamContext();
  const [selectedPeriod, setSelectedPeriod] = useState<IMonthAndYear>({
    month: new Date().getMonth(),
    year: new Date().getFullYear()
  });

  const sortedEvent = useMemo(() => eventsAndRides?.sort((a: IEventAndRides, b: IEventAndRides) => (+new Date(a.event.startTime)) - (+new Date(b.event.startTime))), [eventsAndRides]);
  const upcomingEvents = useMemo(() => sortedEvent?.filter(ev => isUpcoming(ev.event.startTime)), [sortedEvent]);
  const nextUpcomingEvent = useMemo(() => (upcomingEvents?.length || 0) > 0 ? upcomingEvents[0] : null, [upcomingEvents]);
  const firstAvailablePeriod = useMemo(() => {
    const firstEvent = (sortedEvent?.length || 0) > 0 ? sortedEvent[0] : null;
    if (!firstEvent)
      return null;
    const firstEventDate = new Date(firstEvent.event.startTime);
    return {
      month: firstEventDate.getMonth(),
      year: firstEventDate.getFullYear()
    } as IMonthAndYear;
  }, [sortedEvent]);

  const lastAvailablePeriod = useMemo(() => {
    const lastEvent = (sortedEvent?.length || 0) > 0 ? sortedEvent[sortedEvent.length - 1] : null;
    if (!lastEvent)
      return null;
    const lastEventDate = new Date(lastEvent.event.startTime);
    return {
      month: lastEventDate.getMonth(),
      year: lastEventDate.getFullYear()
    } as IMonthAndYear;
  }, [sortedEvent]);

  const allPeriodFirstDays = useMemo(() => {
    if (!firstAvailablePeriod || !lastAvailablePeriod)
      return [];

    const firstPeriodFirstDay = new Date(firstAvailablePeriod.year, firstAvailablePeriod.month, 1);
    const lastPeriodFirstDay = new Date(lastAvailablePeriod.year, lastAvailablePeriod.month, 1);
    const firstDays: Date[] = [];
    let d: Date = firstPeriodFirstDay;
    while (d <= lastPeriodFirstDay) {
      firstDays.push(d);
      d = addMonths(d, 1);
    }
    return firstDays;
  }, [firstAvailablePeriod, lastAvailablePeriod]);

  const getFirstEventOfMonth = useCallback((month: number) => {
    if (!eventsAndRides)
      return null;
    const eventsOfMonth = eventsAndRides.filter(ev => new Date(ev.event.startTime).getMonth() === month);
    return eventsOfMonth.length > 0 ? eventsOfMonth[0] : null;
  }, [eventsAndRides]);

  const getEventsInPeriod = useCallback((period: IMonthAndYear) => {
    const filteredEvents = eventsAndRides.filter(ev => {
      const evDate = new Date(ev.event.startTime);
      return evDate.getMonth() === period.month && evDate.getFullYear() === period.year;
    });
    return filteredEvents;
  }, [eventsAndRides]);

  useEffect(() => {
    // scroll to the next upcoming event
    if (nextUpcomingEvent) {
      const el = document.getElementById(`event_${nextUpcomingEvent.event.id}`);
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [nextUpcomingEvent]);

  useEffect(() => {
    const ev = getFirstEventOfMonth(selectedPeriod.month);
    if (ev) {
      const el = document.getElementById(`event_${ev.event.id}`);
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [selectedPeriod, getFirstEventOfMonth]);


  if (isLoading) {
    return <Loading />;
  }

  return <div className="EventsList">
    {isLoading && <div>Loading...</div>}
    <MonthSelector
      minPeriod={firstAvailablePeriod}
      maxPeriod={lastAvailablePeriod}
      selectedPeriod={selectedPeriod}
      setSelectedPeriod={setSelectedPeriod} />
    <div className="CardsContainer">
      {allPeriodFirstDays.map(d => <EventCardsGroup events={getEventsInPeriod({
        month: d.getMonth(),
        year: d.getFullYear()
      })}
        periodFirstDay={d} />)}
      <br /><br /><br />
      {/* replace BRs by spacing div */}
    </div>
  </div>
}