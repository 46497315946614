import { IPublicClientApplication } from "@azure/msal-browser";
import { AccessTokenBasedService } from "./AccessTokenBasedService";
import { ITeam } from "../models/ITeam";
import { ITeamMembership } from "../models/ITeamMembership";

export class TeamsService extends AccessTokenBasedService {

    constructor(public msalInstance: IPublicClientApplication) {
        super(msalInstance);
    }

    async getMembers(teamId: string): Promise<ITeamMembership[]> {
        try {
            const client = await this.getClient();
            const members = await client.get<ITeamMembership[]>(`/teams/${teamId}/members`);
            return members;
        } catch (error) {
            console.error('Error while trying to get team members');
            throw new Error("Could not get team members");
        }
    }

    async addTeam(team: ITeam): Promise<ITeam> {
        try {
            const client = await this.getClient();
            const createdTeam = await client.post<ITeam, ITeam>(`/teams`, team);
            return createdTeam;
        } catch (error) {
            console.error('Error while trying to add new team');
            throw new Error("Could not add a new team");
        }
    }

    async updateTeam(team: ITeam): Promise<void> {
        try {
            const client = await this.getClient();
            if (!team.id)
                throw new Error("Team id is not specified, team cannot be updated");
            await client.put<ITeam, unknown>(`/teams/${team.id}`, team);
        } catch (error) {
            console.error('Error while trying to update team');
            throw new Error("Could not update team");
        }
    }

    async regenerateJoinCode(teamId: string): Promise<string> {
        try {
            const client = await this.getClient();
            const newJoinCode = await client.post<unknown, string>(`/teams/${teamId}/regenerateJoinCode`);
            return newJoinCode;
        } catch (error) {
            console.error('Error while trying to regenerate team join code');
            throw new Error("Could not regenerate team join code");
        }
    }

}