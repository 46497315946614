import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { EventsList } from './components/EventsList/EventsList';
import { TeamContextLayout } from './components/Layout/TeamContextLayout';
import { EventDetails } from './components/EventDetails/EventDetails';
import { BookEventRide } from './components/BookEventRide/BookEventRide';
import { TeamSelector } from './components/TeamSelector/TeamSelector';
import { UserContextProvider } from './contexts/UserContext';
import { AppLayout } from './components/Layout/Layout';
import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './auth/authConfig';
import { MsalProvider } from '@azure/msal-react';
import { Profile } from './components/Profile/Profile';
import { Redirect } from './components/Redirect/Redirect';
import { NewTeamForm } from './components/NewTeamForm/NewTeamForm';
import { Onboarding } from './components/Onboarding/Onboarding';
import { CurrentTeamDetails } from './components/TeamDetails/TeamDetails';
import { DialogProvider } from './components/common/Dialog/DialogProvider';
// import { customizeTheme } from './theme/theme-config';
import { ThemeProvider } from '@emotion/react';
import { customizeTheme } from './theme/theme-config';
import { RideForm } from './components/RideForm/RideForm';
import { AppContextProvider } from './contexts/AppContext';
import { EventContextLayout } from './components/Layout/EventContextLayout';
// import { ThemeProvider } from 'react-ui';
const theme = customizeTheme;

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }
});

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

export const App = () => {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <MsalProvider instance={msalInstance}>
          <AppContextProvider>
            <DialogProvider>
              <BrowserRouter>
                <UserContextProvider>
                  <Routes>
                    <Route path='/redirect' element={<Redirect />} />
                    <Route path='/team'>
                      <Route path=":teamId" element={<TeamContextLayout />}>
                        <Route path='events' element={<EventsList />} />
                      </Route>
                    </Route>
                    <Route path='/team/:teamId/event' element={<EventContextLayout />}>
                      <Route path=":eventId/book" element={<BookEventRide />} />
                      <Route path=":eventId/applyAsDriver" element={<RideForm />} />
                      <Route path=':eventId' element={<EventDetails />} />
                    </Route>
                    <Route path='/my-team' element={<TeamContextLayout />} >
                      <Route path='' element={<CurrentTeamDetails />} />
                    </Route>
                    <Route path='/' element={<AppLayout />} >
                      <Route path='onboarding' element={<Onboarding />} />
                      <Route path='admin' element={<NewTeamForm />} />
                      <Route path='profile' element={<Profile />} />
                      <Route path='select-team' element={<TeamSelector />} />
                      <Route path='' element={<Redirect />} />
                    </Route>
                  </Routes>
                </UserContextProvider>
              </BrowserRouter>
            </DialogProvider>
          </AppContextProvider>
        </MsalProvider>
      </ThemeProvider>
    </div>
  );
};