import getConfig from '../config/config';
import { Configuration } from "@azure/msal-browser";

const config = getConfig('PROD');

const b2cPolicies = {
    names: {
        signUpSignIn: "B2C_1_SignUpSignIn"
    },
    authorities: {
        signUpSignIn: {
            authority: "https://teamsride.b2clogin.com/teamsride.onmicrosoft.com/B2C_1_SignUpSignIn",
        }
    },
    authorityDomain: "teamsride.b2clogin.com"
}

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: "5645f7e1-aaca-4a7f-b8ac-d46c8fbab4e3", // This is the ONLY mandatory field; everything else is optional.
        authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose sign-up/sign-in user-flow as your default.
        knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
        redirectUri: config.app.redirectUri, // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    },
};

export const api_scope = 'https://teamsride.onmicrosoft.com/api/user_impersonation';