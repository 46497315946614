import { useIsAuthenticated } from "@azure/msal-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Loading } from "../Loading/Loading";
import { useUserContext } from "../../contexts/UserContext";

export const Redirect = () => {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const { user, loaded, selectedTeamId } = useUserContext();

  // useEffect(() => {

  // }, [context.selectedTeamId, context.loaded, navigate]);


  useEffect(() => {
    // setTimeout(() => {

    const onboardedStatus = localStorage.getItem('TeamsRide_onboarded');

    if (isAuthenticated && user.isNewcomer && onboardedStatus !== 'done') {
      navigate('/onboarding');
      return;
    }

    if (!loaded)
      return;

    if (selectedTeamId) {
      navigate(`/team/${selectedTeamId}/events`);
    } else {
      navigate(`/select-team`);
    }

    // }, 60000);
  }, [isAuthenticated, user, loaded, selectedTeamId, navigate]);

  return <Loading message="Authentification en cours..." />;
};