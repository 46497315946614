import "./NavBar.css";
import { Link } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";
import { CalendarMonth, DirectionsCar, Groups2, AccountCircle, AdminPanelSettings } from '@mui/icons-material';
import { useUserContext } from "../../contexts/UserContext";
export const NavBar = () => {
	const isAuthenticated = useIsAuthenticated();
	const { user } = useUserContext();
	const { isSuperAdmin } = user;
	return (
		<nav className="navbar">
			<div className="Menu">
				{isAuthenticated && <>
					<div className="ItemMenu">
						<Link to='/'>
							<CalendarMonth />
							Calendrier</Link>
					</div>
					<div className="ItemMenu">
						<Link to='/my-rides'>
							<DirectionsCar />
							Rides
						</Link>
					</div>
					<div className="ItemMenu">
						<Link to='/my-team'>
							<Groups2 />
							Equipe</Link>
					</div>
					{isSuperAdmin && <div className="ItemMenu">
						<Link to='/admin'>
							<AdminPanelSettings />
							Admin.</Link>
					</div>}
					<div className="ItemMenu">
						<Link to='/profile'>
							<AccountCircle />
							Profil</Link>
					</div>

				</>}
			</div>
		</nav>
	);
}