import { useCallback, useState } from "react";
import { useUserContext } from "../../contexts/UserContext";
import { ITeam } from "../../models/ITeam";
import { useNavigate } from "react-router-dom";
import { Input } from "@mui/material";

export const NewTeamForm = () => {
    console.log('NewTeamForm');
    const navigate = useNavigate();
    const [teamName, setTeamName] = useState<string>('');

    const { addTeam } = useUserContext();
    const callAddTeam = useCallback(async () => {
        await addTeam({ name: teamName } as ITeam);
        navigate('/');
    }, [teamName, addTeam, navigate]);

    return <div className="NewTeamForm">
        <label>Nom de l'équipe</label>
        <Input value={teamName} onChange={(ev) => setTeamName(ev.target.value)} />
        <button onClick={() => callAddTeam()}>Créer</button>
    </div>
};