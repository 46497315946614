import './Loading.css';
export interface ILoadingProps {
  message?: string;
}

export const Loading = ({ message }: ILoadingProps) => {

  return <div className="ContainerLoading">
    <div className="Loading">
      {message || 'En cours de chargement...'}
      <svg className="CarLoadings" xmlns="http://www.w3.org/2000/svg" data-name="Calque 2" viewBox="0 0 841.79 403.71">
        <g id="Layer">
          <g>
            <path className="cls-7-car" d="m682.48,403.71H85.11c-108.65,0-118.22-26.66,0-26.66h597.37c77.24,0,84.81,26.66,0,26.66Z" />
            <g>
              <g>
                <path className="cls-2-car" d="m641.9,220h-41.36c-.6,0-1.08-.48-1.08-1.08v-28.7c0-.6.48-1.08,1.08-1.08h41.36c3.61,0,6.54,2.93,6.54,6.54v17.78c0,3.61-2.93,6.54-6.54,6.54Zm-40.28-2.16h40.28c2.41,0,4.38-1.96,4.38-4.38v-17.78c0-2.41-1.96-4.38-4.38-4.38h-40.28v26.53Z" />
                <path className="cls-1-yellow" d="m673.65,269.47c0,15.29-4.05,29.7-19.34,29.75l-628.5,2.07c-14.61.05-20.1-13.55-20.1-28.16,0-20.55,4.23-37.29,17.49-54.25,10.64-13.6,23.01-26.16,36.41-36.94,52.06-41.92,104.41-40.04,125.55-44.58C200.28,137.35,226.95.02,317.15.02h52.3c52.29,0,131.84-1.15,168.59,16.62,1.57.76,3.13,1.63,4.67,2.59,34.54,21.53,62.75,92.01,86.02,118.09,38.19,42.73,44.92,88.64,44.92,132.15Z" />
                <path className="cls-2-car" d="m241.4,294.09c-19.05,0-29.82-12.77-42.3-27.55-17.46-20.69-37.24-44.14-86.43-44.14-.57,0-1.02-.46-1.02-1.02s.46-1.02,1.02-1.02c50.14,0,71.13,24.88,87.99,44.86,12.14,14.39,22.63,26.82,40.73,26.82.57,0,1.02.46,1.02,1.02s-.46,1.02-1.02,1.02Z" />
                <path className="cls-2-car" d="m462.83,295.96c-.09,0-.18-.01-.27-.04-.55-.15-.87-.71-.72-1.25,5.59-20.9,19.06-39.81,37.93-53.27,19.32-13.78,42.6-21.06,67.33-21.06.11,0,.22,0,.33,0,33.53.09,60.95,11.52,83.82,34.95.4.4.39,1.05-.02,1.45-.41.39-1.05.39-1.45-.02-22.78-23.33-48.95-34.24-82.36-34.33h-.32c-49.36,0-91.81,29.9-103.29,72.81-.12.46-.54.76-.99.76Z" />
                <path className="cls-3-car" d="m628.74,137.32c-22.48-.54-33.48-11.32-86.37-92.6-9.06-13.93-4.13-21.82.35-25.49,34.54,21.53,62.75,92.01,86.02,118.09Z" />
                <path className="cls-3-car" d="m532.26,137.35H246.54c-7.37,0-11.76.36-17.67-1.22-7.8-2.09-8.43-10.16-5.91-17.84,14.45-43.96,48.09-101.66,90.9-101.66h146.69c26.57,0,39.42,25.84,54.08,56.02,14.67,30.18,31.15,64.7,17.64,64.7Z" />
                <g>
                  <path className="cls-4-car" d="m196.49,144.91h-5.28c-7.51,0-13.59-6.09-13.59-13.59s6.09-13.59,13.59-13.59h5.28c2.93,0,5.3,2.37,5.3,5.3v16.59c0,2.93-2.37,5.3-5.3,5.3Z" />
                  <path className="cls-2-car" d="m196.49,145.99h-5.28c-8.09,0-14.68-6.58-14.68-14.68s6.58-14.67,14.68-14.67h5.28c3.52,0,6.38,2.86,6.38,6.38v16.59c0,3.52-2.86,6.38-6.38,6.38Zm-5.28-27.19c-6.9,0-12.51,5.61-12.51,12.51s5.61,12.51,12.51,12.51h5.28c2.32,0,4.21-1.89,4.21-4.21v-16.59c0-2.32-1.89-4.21-4.21-4.21h-5.28Z" />
                </g>
                <g>
                  <path className="cls-4-car" d="m343.01,200.79h-25.68c-4.59,0-8.31-3.72-8.31-8.31s3.72-8.31,8.31-8.31h25.68c4.59,0,8.31,3.72,8.31,8.31s-3.72,8.31-8.31,8.31Z" />
                  <path className="cls-2-car" d="m343.01,201.87h-25.68c-5.18,0-9.39-4.21-9.39-9.39s4.21-9.39,9.39-9.39h25.68c5.18,0,9.39,4.21,9.39,9.39s-4.21,9.39-9.39,9.39Zm-25.68-16.62c-3.98,0-7.22,3.24-7.22,7.23s3.24,7.23,7.22,7.23h25.68c3.98,0,7.23-3.24,7.23-7.23s-3.24-7.23-7.23-7.23h-25.68Z" />
                </g>
                <g>
                  <circle className="cls-4-car" cx="145.15" cy="186.44" r="8.31" />
                  <path className="cls-2-car" d="m145.15,195.83c-5.18,0-9.39-4.21-9.39-9.39s4.21-9.39,9.39-9.39,9.39,4.21,9.39,9.39-4.21,9.39-9.39,9.39Zm0-16.61c-3.98,0-7.23,3.24-7.23,7.22s3.24,7.23,7.23,7.23,7.22-3.24,7.22-7.23-3.24-7.22-7.22-7.22Z" />
                </g>
                <rect className="cls-5-car" x="4.24" y="304.38" width="682.02" height="29.25" rx="12.85" ry="12.85" />
                <g>
                  <circle className="cls-2-car" cx="112.68" cy="315.21" r="73.63" />
                  <circle className="cls-3-car" cx="112.68" cy="315.21" r="45.24" />
                  <path className="cls-2-car" d="m112.68,353.74c-21.25,0-38.53-17.28-38.53-38.53,0-.7.57-1.27,1.27-1.27s1.27.57,1.27,1.27c0,19.84,16.14,35.99,35.99,35.99.7,0,1.27.57,1.27,1.27s-.57,1.27-1.27,1.27Z" />
                  <path className="cls-2-car" d="m149.93,316.48c-.7,0-1.27-.57-1.27-1.27,0-19.84-16.14-35.99-35.99-35.99-.7,0-1.27-.57-1.27-1.27s.57-1.27,1.27-1.27c21.24,0,38.53,17.28,38.53,38.53,0,.7-.57,1.27-1.27,1.27Z" />
                  <path className="cls-2-car" d="m112.68,332.45c-4.61,0-8.93-1.79-12.19-5.05-3.26-3.26-5.05-7.58-5.05-12.19s1.79-8.93,5.05-12.19c3.26-3.26,7.58-5.05,12.19-5.05s8.93,1.79,12.19,5.05c3.26,3.26,5.05,7.58,5.05,12.19s-1.79,8.93-5.05,12.19c-3.26,3.26-7.58,5.05-12.19,5.05Zm0-31.94c-3.93,0-7.62,1.53-10.39,4.3-2.78,2.78-4.3,6.47-4.3,10.39s1.53,7.62,4.3,10.39c2.78,2.78,6.47,4.3,10.39,4.3s7.62-1.53,10.39-4.3c2.78-2.78,4.3-6.47,4.3-10.39s-1.53-7.62-4.3-10.39c-2.78-2.78-6.47-4.3-10.39-4.3Z" />
                </g>
                <g>
                  <circle className="cls-2-car" cx="565.02" cy="315.21" r="73.63" />
                  <circle className="cls-3-car" cx="565.02" cy="315.21" r="45.24" />
                  <path className="cls-2-car" d="m565.02,353.74c-21.25,0-38.53-17.28-38.53-38.53,0-.7.57-1.27,1.27-1.27s1.27.57,1.27,1.27c0,19.84,16.14,35.99,35.99,35.99.7,0,1.27.57,1.27,1.27s-.57,1.27-1.27,1.27Z" />
                  <path className="cls-2-car" d="m602.27,316.48c-.7,0-1.27-.57-1.27-1.27,0-19.84-16.14-35.99-35.99-35.99-.7,0-1.27-.57-1.27-1.27s.57-1.27,1.27-1.27c21.25,0,38.53,17.28,38.53,38.53,0,.7-.57,1.27-1.27,1.27Z" />
                  <path className="cls-2-car" d="m565.02,332.45c-4.6,0-8.93-1.79-12.19-5.05-6.72-6.72-6.72-17.66,0-24.38,3.26-3.26,7.58-5.05,12.19-5.05s8.93,1.79,12.19,5.05c6.72,6.72,6.72,17.66,0,24.38-3.26,3.26-7.58,5.05-12.19,5.05Zm0-31.94c-3.93,0-7.62,1.53-10.39,4.3-2.78,2.78-4.3,6.47-4.3,10.39s1.53,7.62,4.3,10.39,6.47,4.3,10.39,4.3,7.62-1.53,10.39-4.3,4.3-6.47,4.3-10.39-1.53-7.62-4.3-10.39c-2.78-2.78-6.47-4.3-10.39-4.3Z" />
                </g>
                <path className="cls-3-car" d="m52.6,211.65c-9.03,9.05-21.33,11.81-29.39,7.22,10.63-13.59,23-26.16,36.42-36.94,4.86,8.05,2.14,20.57-7.02,29.72Z" />
                <g>
                  <path className="cls-4-car" d="m23.21,218.87c10.63-13.59,23-26.16,36.42-36.94l-36.42,36.94Z" />
                  <path className="cls-2-car" d="m23.21,219.95c-.23,0-.47-.08-.67-.23-.47-.37-.55-1.05-.19-1.52,10.77-13.77,23.08-26.26,36.59-37.12.46-.38,1.15-.3,1.52.17.38.47.3,1.15-.16,1.52-13.38,10.76-25.57,23.13-36.24,36.77-.21.27-.53.42-.85.42Z" />
                </g>
                <path className="cls-2-car" d="m341.32,280.48h-70.03c-9.07,0-18-2.72-25.15-7.66-7.18-4.96-12.34-12-14.52-19.83l-23.38-83.62c-.17-.61.19-1.25.8-1.42.61-.17,1.25.19,1.42.8l23.38,83.62c2.04,7.31,6.88,13.9,13.62,18.55,6.77,4.67,15.23,7.25,23.84,7.25h70.03c24.82,0,45.01-17.75,45.01-39.56V22.73c0-.64.52-1.15,1.15-1.15s1.15.52,1.15,1.15v215.9c0,23.08-21.23,41.86-47.32,41.86Z" />
                <path className="cls-2-car" d="m568.03,177.73h-6.03c-4.33,0-7.86-3.52-7.86-7.86s3.52-7.86,7.86-7.86h6.03c4.33,0,7.86,3.52,7.86,7.86s-3.52,7.86-7.86,7.86Zm-6.03-13.55c-3.14,0-5.69,2.55-5.69,5.69s2.55,5.69,5.69,5.69h6.03c3.14,0,5.69-2.55,5.69-5.69s-2.55-5.69-5.69-5.69h-6.03Z" />
                <path className="cls-2-car" d="m627.53,138.43c-.38,0-.75-.2-.95-.56-.21-.37-20.73-37.48-39.77-66.21-24.63-37.16-45.72-51.39-45.93-51.53-.5-.33-.63-1-.3-1.5.33-.5,1-.63,1.5-.3.21.14,21.64,14.56,46.54,52.14,19.09,28.81,39.65,65.99,39.86,66.36.29.52.1,1.18-.42,1.47-.17.09-.34.13-.52.13Z" />
              </g>
              <path className="cls-2-car" d="m664.44,159.93h-79.93c-.6,0-1.08-.48-1.08-1.08s.48-1.08,1.08-1.08h79.93c.6,0,1.08.48,1.08,1.08s-.48,1.08-1.08,1.08Z" />
              <path className="cls-2-car" d="m767.94,188.63h-80.96c-.6,0-1.08-.48-1.08-1.08s.48-1.08,1.08-1.08h80.96c.6,0,1.08.48,1.08,1.08s-.48,1.08-1.08,1.08Z" />
            </g>
            <g>
              <path className="cls-1-yellow" d="m805.74,52.35c-.12-2.65-2.15-4.89-4.89-4.89-2.56,0-5.01,2.25-4.89,4.89.12,2.65,2.15,4.89,4.89,4.89,2.56,0,5.01-2.25,4.89-4.89Z" />
              <path className="cls-6-car" d="m682.3,72.32c-3.44,0-6.37,2.93-6.37,6.37s2.91,6.37,6.37,6.37,6.37-2.93,6.37-6.37-2.91-6.37-6.37-6.37Z" />
              <path className="cls-6-car" d="m462.63,196.01c-3.44,0-6.37,2.93-6.37,6.37s2.91,6.37,6.37,6.37,6.37-2.93,6.37-6.37-2.91-6.37-6.37-6.37Z" />
              <path className="cls-2-car" d="m724.33,99.76c-4.71,0-8.7-3.98-8.7-8.7s3.98-8.7,8.7-8.7,8.7,3.98,8.7,8.7-3.98,8.7-8.7,8.7Zm0-15.08c-3.46,0-6.39,2.92-6.39,6.38s2.92,6.39,6.39,6.39,6.38-2.92,6.38-6.39-2.92-6.38-6.38-6.38Z" />
              <path className="cls-6-car" d="m841.49,249.92c-.03-.06-.06-.12-.09-.19-.1-.21-.23-.37-.39-.55-.18-.2-.4-.33-.65-.42-.24-.13-.5-.18-.77-.17-.52.02-1.06.19-1.42.59-.15.17-.3.35-.39.55-.03.06-.06.12-.09.19-.2.34-.3.71-.3,1.11,0,.4.1.77.3,1.11.39.67,1.12,1.09,1.9,1.09.77,0,1.51-.42,1.9-1.09.2-.34.3-.71.3-1.11,0-.4-.1-.77-.3-1.11Z" />
              <path className="cls-2-car" d="m674.36,10.67c-.03-.06-.06-.12-.09-.19-.1-.21-.23-.37-.39-.55-.18-.2-.4-.33-.65-.42-.24-.13-.5-.18-.77-.17-.52.02-1.06.19-1.42.59-.15.17-.3.35-.39.55-.03.06-.06.12-.09.19-.2.34-.3.71-.3,1.11,0,.4.1.77.3,1.11.39.67,1.12,1.09,1.9,1.09s1.51-.42,1.9-1.09c.2-.34.3-.71.3-1.11,0-.4-.1-.77-.3-1.11Z" />
              <path className="cls-2-car" d="m463.23,142.89c-.03-.06-.06-.12-.09-.19-.1-.21-.23-.37-.39-.55-.18-.2-.4-.33-.65-.42-.24-.13-.5-.18-.77-.17-.52.02-1.06.19-1.42.59-.15.17-.3.35-.39.55-.03.06-.06.12-.09.19-.2.34-.3.71-.3,1.11,0,.4.1.77.3,1.11.39.67,1.12,1.09,1.9,1.09s1.51-.42,1.9-1.09c.2-.34.3-.71.3-1.11,0-.4-.1-.77-.3-1.11Z" />
              <path className="cls-2-car" d="m753.02,49.03c-.02-.05-.05-.1-.07-.15-.08-.17-.19-.3-.32-.45-.15-.16-.32-.27-.52-.34-.19-.1-.4-.15-.63-.14-.42.02-.86.16-1.15.48-.12.14-.24.28-.32.45-.02.05-.05.1-.07.15-.16.28-.24.58-.24.9,0,.32.08.62.24.9.32.54.91.88,1.54.88s1.22-.34,1.54-.88c.16-.28.24-.58.24-.9,0-.32-.08-.62-.24-.9Z" />
              <path className="cls-2-car" d="m799.04,150.93l-.09-.19c-.1-.21-.23-.37-.39-.55-.18-.2-.4-.33-.65-.42-.24-.13-.5-.18-.77-.17-.52.02-1.06.19-1.42.59-.15.17-.3.35-.39.55-.03.06-.06.12-.09.19-.2.34-.3.71-.3,1.11,0,.4.1.77.3,1.11.39.67,1.12,1.09,1.9,1.09.77,0,1.51-.42,1.9-1.09.2-.34.3-.71.3-1.11,0-.4-.1-.77-.3-1.11Z" />
              <path className="cls-6-car" d="m733.01,155.61c-.03-.06-.06-.12-.09-.19-.1-.21-.23-.37-.39-.55-.18-.2-.4-.33-.65-.42-.24-.13-.5-.18-.77-.17-.52.02-1.06.19-1.42.59-.15.17-.3.35-.39.55l-.09.19c-.2.34-.3.71-.3,1.11,0,.4.1.77.3,1.11.39.67,1.12,1.09,1.9,1.09s1.51-.42,1.9-1.09c.2-.34.3-.71.3-1.11,0-.4-.1-.77-.3-1.11Z" />
              <path className="cls-1-yellow" d="m622.74,5.24s-.03.04,0,.04.03-.04,0-.04Z" />
              <path className="cls-1-yellow" d="m836.83,163.08c-5.58,0-5.6,8.67,0,8.67,5.58,0,5.6-8.67,0-8.67Z" />
              <path className="cls-1-yellow" d="m619.77,10.68c-.08-.05-.16-.1-.23-.15-1.14-.72-2.7-.67-3.85,0-1.16.68-1.89,1.95-1.89,3.29s.73,2.61,1.89,3.29c1.15.67,2.71.72,3.85,0,.08-.05.16-.1.23-.15,1.1-.69,1.8-1.83,1.8-3.14s-.71-2.45-1.8-3.14Z" />
              <g>
                <path className="cls-2-car" d="m718.09,17.05c-.52,0-.94-.42-.94-.94v-2.79c0-.52.42-.94.94-.94s.94.42.94.94v2.79c0,.52-.42.94-.94.94Z" />
                <path className="cls-2-car" d="m718.16,24.35c-.52,0-.94-.42-.94-.94v-2.79c0-.52.42-.94.94-.94s.94.42.94.94v2.79c0,.52-.42.94-.94.94Z" />
                <path className="cls-2-car" d="m723.17,19.27h-2.79c-.52,0-.94-.42-.94-.94s.42-.94.94-.94h2.79c.52,0,.94.42.94.94s-.42.94-.94.94Z" />
                <path className="cls-2-car" d="m715.87,19.34h-2.79c-.52,0-.94-.42-.94-.94s.42-.94.94-.94h2.79c.52,0,.94.42.94.94s-.42.94-.94.94Z" />
              </g>
              <g>
                <path className="cls-2-car" d="m777.76,265.01c-.64,0-1.16-.52-1.16-1.16v-3.92c0-.64.52-1.15,1.16-1.15s1.16.52,1.16,1.16v3.92c0,.64-.52,1.15-1.16,1.15Z" />
                <path className="cls-2-car" d="m777.85,275.25c-.64,0-1.16-.52-1.16-1.16v-3.92c0-.64.52-1.15,1.16-1.15s1.16.52,1.16,1.16v3.92c0,.64-.52,1.15-1.16,1.15Z" />
                <path className="cls-2-car" d="m784.89,268.13s-3.92,0-3.92,0c-.64,0-1.15-.52-1.15-1.16,0-.64.52-1.15,1.16-1.15,0,0,3.92,0,3.92,0,.64,0,1.15.52,1.15,1.16,0,.64-.52,1.15-1.16,1.15Z" />
                <path className="cls-2-car" d="m774.64,268.22s-3.92,0-3.92,0c-.64,0-1.15-.52-1.15-1.16,0-.64.52-1.15,1.16-1.15,0,0,3.92,0,3.92,0,.64,0,1.15.52,1.15,1.16,0,.64-.52,1.15-1.16,1.15Z" />
              </g>
              <g>
                <path className="cls-2-car" d="m771.7,121.79c-.64,0-1.16-.52-1.16-1.16v-3.92c0-.64.52-1.15,1.16-1.15s1.16.52,1.16,1.16v3.92c0,.64-.52,1.15-1.16,1.15Z" />
                <path className="cls-2-car" d="m771.78,132.03c-.64,0-1.16-.52-1.16-1.16v-3.92c0-.64.52-1.15,1.16-1.15.64,0,1.16.52,1.16,1.16v3.92c0,.64-.52,1.15-1.16,1.15Z" />
                <path className="cls-2-car" d="m778.82,124.91s-3.92,0-3.92,0c-.64,0-1.15-.52-1.15-1.16s.52-1.15,1.16-1.15c0,0,3.92,0,3.92,0,.64,0,1.15.52,1.15,1.16s-.52,1.15-1.16,1.15Z" />
                <path className="cls-2-car" d="m768.58,125s-3.92,0-3.92,0c-.64,0-1.15-.52-1.15-1.16,0-.64.52-1.15,1.16-1.15,0,0,3.92,0,3.92,0,.64,0,1.15.52,1.15,1.16,0,.64-.52,1.15-1.16,1.15Z" />
              </g>
              <g>
                <path className="cls-2-car" d="m510.45,176.18c-.64,0-1.16-.52-1.16-1.16v-3.92c0-.64.52-1.15,1.16-1.15s1.16.52,1.16,1.16v3.92c0,.64-.52,1.15-1.16,1.15Z" />
                <path className="cls-2-car" d="m510.53,186.42c-.64,0-1.16-.52-1.16-1.16v-3.92c0-.64.52-1.15,1.16-1.15s1.16.52,1.16,1.16v3.92c0,.64-.52,1.15-1.16,1.15Z" />
                <path className="cls-2-car" d="m517.57,179.3s-3.92,0-3.92,0c-.64,0-1.15-.52-1.15-1.16s.52-1.15,1.16-1.15c0,0,3.92,0,3.92,0,.64,0,1.15.52,1.15,1.16s-.52,1.15-1.16,1.15Z" />
                <path className="cls-2-car" d="m507.33,179.38s-3.92,0-3.92,0c-.64,0-1.15-.52-1.15-1.16s.52-1.15,1.16-1.15c0,0,3.92,0,3.92,0,.64,0,1.15.52,1.15,1.16s-.52,1.15-1.16,1.15Z" />
              </g>
              <path className="cls-2-car" d="m686.47,37.13h-.67c-.67,0-1.22-.55-1.22-1.22s.54-1.22,1.22-1.22h.67c.67,0,1.22.54,1.22,1.22s-.54,1.22-1.22,1.22Zm-7.43,0h-.67c-.67,0-1.22-.55-1.22-1.22s.54-1.22,1.22-1.22h.67c.67,0,1.22.54,1.22,1.22s-.54,1.22-1.22,1.22Zm-7.43,0h-.67c-.67,0-1.22-.55-1.22-1.22s.54-1.22,1.22-1.22h.67c.67,0,1.22.54,1.22,1.22s-.54,1.22-1.22,1.22Zm-7.43,0h-.67c-.67,0-1.22-.55-1.22-1.22s.55-1.22,1.22-1.22h.67c.67,0,1.22.54,1.22,1.22s-.54,1.22-1.22,1.22Zm-7.43,0h-.67c-.67,0-1.22-.55-1.22-1.22s.54-1.22,1.22-1.22h.67c.67,0,1.22.54,1.22,1.22s-.55,1.22-1.22,1.22Zm-7.43,0h-.67c-.67,0-1.22-.55-1.22-1.22s.54-1.22,1.22-1.22h.67c.67,0,1.22.54,1.22,1.22s-.54,1.22-1.22,1.22Zm-7.43,0h-.67c-.67,0-1.22-.55-1.22-1.22s.54-1.22,1.22-1.22h.67c.67,0,1.22.54,1.22,1.22s-.54,1.22-1.22,1.22Zm-7.43,0h-.67c-.67,0-1.22-.55-1.22-1.22s.54-1.22,1.22-1.22h.67c.67,0,1.22.54,1.22,1.22s-.54,1.22-1.22,1.22Zm-7.43,0h-.67c-.67,0-1.22-.55-1.22-1.22s.55-1.22,1.22-1.22h.67c.67,0,1.22.54,1.22,1.22s-.54,1.22-1.22,1.22Zm-7.43,0h-.67c-.67,0-1.22-.55-1.22-1.22s.54-1.22,1.22-1.22h.67c.67,0,1.22.54,1.22,1.22s-.54,1.22-1.22,1.22Zm-7.43,0h-.67c-.67,0-1.22-.55-1.22-1.22s.54-1.22,1.22-1.22h.67c.67,0,1.22.54,1.22,1.22s-.55,1.22-1.22,1.22Z" />
              <path className="cls-2-car" d="m701.7,136.21h-59.14c-.67,0-1.22-.54-1.22-1.22s.54-1.22,1.22-1.22h59.14c.67,0,1.22.55,1.22,1.22s-.54,1.22-1.22,1.22Z" />
              <path className="cls-2-car" d="m696.99,107.4h-11.81c-.67,0-1.22-.54-1.22-1.22s.54-1.22,1.22-1.22h11.81c.67,0,1.22.55,1.22,1.22s-.54,1.22-1.22,1.22Z" />
              <g>
                <path className="cls-2-car" d="m523.94,224.25h-.67c-.67,0-1.22-.54-1.22-1.22s.54-1.22,1.22-1.22h.67c.67,0,1.22.54,1.22,1.22s-.54,1.22-1.22,1.22Zm-7.43,0h-.67c-.67,0-1.22-.54-1.22-1.22s.54-1.22,1.22-1.22h.67c.67,0,1.22.54,1.22,1.22s-.54,1.22-1.22,1.22Zm-7.43,0h-.67c-.67,0-1.22-.54-1.22-1.22s.55-1.22,1.22-1.22h.67c.67,0,1.22.54,1.22,1.22s-.54,1.22-1.22,1.22Zm-7.43,0h-.67c-.67,0-1.22-.54-1.22-1.22s.54-1.22,1.22-1.22h.67c.67,0,1.22.54,1.22,1.22s-.55,1.22-1.22,1.22Zm-7.43,0h-.67c-.67,0-1.22-.54-1.22-1.22s.54-1.22,1.22-1.22h.67c.67,0,1.22.54,1.22,1.22s-.54,1.22-1.22,1.22Zm-7.43,0h-.67c-.67,0-1.22-.54-1.22-1.22s.54-1.22,1.22-1.22h.67c.67,0,1.22.54,1.22,1.22s-.54,1.22-1.22,1.22Zm-7.43,0h-.67c-.67,0-1.22-.54-1.22-1.22s.54-1.22,1.22-1.22h.67c.67,0,1.22.54,1.22,1.22s-.54,1.22-1.22,1.22Zm-7.43,0h-.67c-.67,0-1.22-.54-1.22-1.22s.54-1.22,1.22-1.22h.67c.67,0,1.22.54,1.22,1.22s-.54,1.22-1.22,1.22Zm-7.43,0h-.67c-.67,0-1.22-.54-1.22-1.22s.54-1.22,1.22-1.22h.67c.67,0,1.22.54,1.22,1.22s-.55,1.22-1.22,1.22Zm-7.43,0h-.67c-.67,0-1.22-.54-1.22-1.22s.54-1.22,1.22-1.22h.67c.67,0,1.22.54,1.22,1.22s-.54,1.22-1.22,1.22Zm-7.43,0h-.67c-.67,0-1.22-.54-1.22-1.22s.54-1.22,1.22-1.22h.67c.67,0,1.22.54,1.22,1.22s-.54,1.22-1.22,1.22Z" />
                <path className="cls-2-car" d="m429.17,224.61h-66.91c-.67,0-1.22-.54-1.22-1.22s.54-1.22,1.22-1.22h66.91c.67,0,1.22.54,1.22,1.22s-.55,1.22-1.22,1.22Z" />
              </g>
              <path className="cls-2-car" d="m817.09,222.12h-.67c-.67,0-1.22-.55-1.22-1.22s.54-1.22,1.22-1.22h.67c.67,0,1.22.54,1.22,1.22s-.54,1.22-1.22,1.22Zm-7.43,0h-.67c-.67,0-1.22-.55-1.22-1.22s.55-1.22,1.22-1.22h.67c.67,0,1.22.54,1.22,1.22s-.54,1.22-1.22,1.22Zm-7.43,0h-.67c-.67,0-1.22-.55-1.22-1.22s.54-1.22,1.22-1.22h.67c.67,0,1.22.54,1.22,1.22s-.55,1.22-1.22,1.22Zm-7.43,0h-.67c-.67,0-1.22-.55-1.22-1.22s.54-1.22,1.22-1.22h.67c.67,0,1.22.54,1.22,1.22s-.55,1.22-1.22,1.22Zm-7.43,0h-.67c-.67,0-1.22-.55-1.22-1.22s.54-1.22,1.22-1.22h.67c.67,0,1.22.54,1.22,1.22s-.54,1.22-1.22,1.22Zm-7.43,0h-.67c-.67,0-1.22-.55-1.22-1.22s.54-1.22,1.22-1.22h.67c.67,0,1.22.54,1.22,1.22s-.54,1.22-1.22,1.22Zm-7.43,0h-.67c-.67,0-1.22-.55-1.22-1.22s.55-1.22,1.22-1.22h.67c.67,0,1.22.54,1.22,1.22s-.54,1.22-1.22,1.22Zm-7.43,0h-.67c-.67,0-1.22-.55-1.22-1.22s.55-1.22,1.22-1.22h.67c.67,0,1.22.54,1.22,1.22s-.54,1.22-1.22,1.22Zm-7.43,0h-.67c-.67,0-1.22-.55-1.22-1.22s.54-1.22,1.22-1.22h.67c.67,0,1.22.54,1.22,1.22s-.55,1.22-1.22,1.22Zm-7.43,0h-.67c-.67,0-1.22-.55-1.22-1.22s.54-1.22,1.22-1.22h.67c.67,0,1.22.54,1.22,1.22s-.54,1.22-1.22,1.22Zm-7.43,0h-.67c-.67,0-1.22-.55-1.22-1.22s.54-1.22,1.22-1.22h.67c.67,0,1.22.54,1.22,1.22s-.54,1.22-1.22,1.22Z" />
            </g>
          </g>
        </g>
      </svg>
    </div>

  </div>;
};