import { createContext, useCallback, useContext, useMemo } from "react";
import { IRide } from "../models/IRide";
import { IEvent } from "../models/IEvent";
import { useTeamContext } from "./TeamContext";

export interface IEventContext {
    eventId: string;
    event?: IEvent;
    applyAsDriver: (ride: IRide) => Promise<void>;
    bookRide: (rideId: string, passengerIds: string[], comments?: string) => Promise<void>;
}

const defaultContext: IEventContext = {
    eventId: '',
    event: null,
    applyAsDriver: async () => { },
    bookRide: async () => { }
};

const EventContext = createContext<IEventContext>(defaultContext);


export interface IEventContextProviderProps {
    children: any;
    eventId: string;
}

export const useEventContext = () => useContext(EventContext);



export const EventContextProvider = ({ eventId, children }: IEventContextProviderProps) => {
    const { applyAsDriver: applyAsDriverToEvent,
        bookRide: bookRideOnEvent,
        eventsAndRides } = useTeamContext();

    const applyAsDriver = useCallback(async (ride: IRide) => {
        await applyAsDriverToEvent(eventId, ride);
    }, [eventId, applyAsDriverToEvent]);

    const bookRide = useCallback(async (rideId: string, passengerIds: string[], comments?: string) => {
        await bookRideOnEvent(eventId, rideId, passengerIds, comments);
    }, [eventId, bookRideOnEvent]);

    const event = useMemo(() => !!eventId ? eventsAndRides.find(ev => ev.event.id === eventId)?.event : null, [eventId, eventsAndRides]);

    return <EventContext.Provider value={{ applyAsDriver, bookRide, eventId, event }}>
        {children}
    </EventContext.Provider>
}