import { useCallback, useEffect, useState } from "react";
import { useTeamContext } from "../../contexts/TeamContext";
import { Loading } from "../Loading/Loading";
import { ITeam } from "../../models/ITeam";
import { TeamSettings } from "../TeamSettings/TeamSettings";
import { TeamMembers } from "../TeamMembers/TeamMembers";
import { ITeamMembership } from "../../models/ITeamMembership";
import { Avatar, Stack } from "@mui/material";
import TeamIcon from '../../assets/icons/TeamIcon.svg';

export interface ITeamDetailsProps {
    team: ITeam;
    getMembers: () => Promise<ITeamMembership[]>;
    updateTeam: (team: ITeam) => void;
    removeMember: (teamId: string, userId: string) => void;
    currentUserIsAdmin: boolean;
    regenerateJoinCode?: () => Promise<string>;
}

export const CurrentTeamDetails = () => {
    const { team, currentUserIsAdmin, regenerateJoinCode, updateTeam, getMembers } = useTeamContext();
    return <TeamDetails team={team}
        getMembers={getMembers}
        removeMember={() => { }}
        updateTeam={updateTeam}
        currentUserIsAdmin={currentUserIsAdmin}
        regenerateJoinCode={regenerateJoinCode} />;
}

export const TeamDetails = ({ team, getMembers, currentUserIsAdmin,
    updateTeam, removeMember, regenerateJoinCode }: ITeamDetailsProps) => {

    const updateTeamSettings = useCallback((team: ITeam) => {
        updateTeam(team);
    }, [updateTeam]);

    const removeTeamMember = useCallback((userId: string) => {
        removeMember(team.id, userId);
    }, [team, removeMember]);

    const [isLoadingMembers, setIsLoadingMembers] = useState<boolean>(false);
    const [members, setMembers] = useState<ITeamMembership[]>([]);
    useEffect(() => {
        if (isLoadingMembers || members.length > 0)
            return;

        setIsLoadingMembers(true);
        getMembers()
            .then(fetchedMembers => setMembers(fetchedMembers))
            .finally(() => setIsLoadingMembers(false));
    }, [getMembers, members, isLoadingMembers, setIsLoadingMembers]);

    if (!team)
        return <Loading />;


    return <div className="TeamDetails" style={{ padding: '2rem' }}>
        <Stack direction='column' alignContent='center' alignItems='center'>
            <Avatar sx={{ width: '8rem', height: '8rem', marginTop: '1rem' }} src={team.avatar || TeamIcon} />
        </Stack>
        <h1>{team.name}</h1>

        <h3>Membres</h3>
        {isLoadingMembers
            ? <Loading />
            : <TeamMembers members={members} team={team} removeMember={currentUserIsAdmin ? removeTeamMember : null} />
        }

        {currentUserIsAdmin && <>
            {/* <h3>Administration</h3> */}
            <TeamSettings team={team} updateTeam={updateTeamSettings} regenerateJoinCode={regenerateJoinCode} />
        </>}
    </div>
};