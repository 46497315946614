import './EventCard.css';
import { IEventAndRides } from '../../models/IEventAndRides';
import { Button, Stack } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTeamContext } from '../../contexts/TeamContext';
import { useNavigate } from 'react-router-dom';
import { getDayNumber, getDayShortName, getMonthShortName, getTime } from '../../helpers/date-helpers';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IRide } from '../../models/IRide';
import { useUserContext } from '../../contexts/UserContext';
import { DirectionsCar } from '@mui/icons-material';

export interface IEventCardProps {
  eventData: IEventAndRides;
}


export function EventCard(props: IEventCardProps) {
  const { eventData } = props;
  const navigate = useNavigate();
  const { user } = useUserContext();
  const { teamId, currentUserIsAdmin } = useTeamContext();

  const goToRideBooking = useCallback(async () => {
    navigate(`/team/${teamId}/event/${eventData.event.id}/book`);
  }, [navigate, eventData, teamId]);

  const applyAsDriver = useCallback(async () => {
    navigate(`/team/${teamId}/event/${eventData.event.id}/applyAsDriver`);
  }, [navigate, eventData, teamId]);


  const iAmADriver = useMemo(() => !!eventData?.rides?.find(r => r.driverId === user.id), [user, eventData]);
  const noRides = (eventData.rides?.length || 0) === 0;
  const availableSeats = useMemo(() => {
    const seats = eventData.rides?.reduce((acc, ride: IRide) => {
      const rideAvailableSeats = ride.availableSeats - (ride.bookings?.length || 0);
      return acc + rideAvailableSeats;
    }, 0);
    return seats || 0;
  }, [eventData]);

  return (
    <div className="CardsAvailableCars" id={`event_${eventData.event.id}`} >

      <div className='AvailableCarsCard'>

        <div className='EventDateCard'>
          <div className='EventDate'>
            <div className='DayDate'>{getDayShortName(eventData.event.startTime)}</div>
            <div className='NumberDayDate'>{getDayNumber(eventData.event.startTime)}</div>
            <div className='MonthDate'>{getMonthShortName(eventData.event.startTime)}</div>
            <div className='HourEvent'>{getTime(eventData.event.startTime)}</div>
          </div>
        </div>
        <div className='AvailableCars'>

          <div className='TitleTrip'>
            <Stack direction='row' justifyContent='space-between' alignItems='center' alignContent='right' >
              {eventData.event.name}

              <MoreVertIcon onClick={() => navigate(`/team/${teamId}/event/${eventData.event.id}`)} />
            </Stack>
          </div>
          <div className='LocationEvent'>
            <div className='IconLocation'>
              <LocationOnIcon />
              <div className='Location'>{eventData.event.location?.name}</div>
            </div>
          </div>
          <div className=''>
            {eventData.rides.slice(0, 1).map(ride => (
              <div className='RideInfo' key={ride.id}>
                <div className='RideIcons'>
                  {eventData.rides.map(() => (
                    <DirectionsCar />
                    // <svg className='CarInfo' width="12" height="12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048"><path d="M384 1152q27 0 50 10t40 27 28 41 10 50q0 27-10 50t-27 40-41 28-50 10q-27 0-50-10t-40-27-28-41-10-50q0-27 10-50t27-40 41-28 50-10zm1280 0q27 0 50 10t40 27 28 41 10 50q0 27-10 50t-27 40-41 28-50 10q-27 0-50-10t-40-27-28-41-10-50q0-27 10-50t27-40 41-28 50-10zm347-256l-66 65q2 5 10 30t19 59 25 73 24 71 18 54 7 22v650q0 27-10 50t-27 40-41 28-50 10h-128q-27 0-50-10t-40-27-28-41-10-50H384q0 27-10 50t-27 40-41 28-50 10H128q-27 0-50-10t-40-27-28-41-10-50v-650l7-21 18-54 24-72q13-39 24-73t20-59 10-30l-66-65H0V768h91l57 58 74-223q16-49 46-89t71-69 87-45 100-16h996q52 0 99 16t88 44 70 69 47 90l74 223 57-58h91v128h-37zM526 512q-63 0-112 36t-70 95l-85 253h1530l-85-253q-20-59-69-95t-113-36H526zm882 1231l-104-207H744l-104 207v49h768v-49zm512 49v-502l-6-18q-6-18-15-47t-21-61-21-63-17-51-9-26H217q-2 5-9 26t-17 50-21 63-20 62-16 46-6 19v502h384v-79l152-305h720l152 305v79h384z" fill="green"></path></svg>
                  ))}
                </div>
              </div>
            ))}

            <div>
              {noRides && <span className='NoRides'>Aucun ride disponible...</span>}
              {!noRides && <div className={`SeatsInfo ${availableSeats > 0 ? 'Available' : 'Full'}`}>
                {availableSeats > 0 ? `${availableSeats} place${(availableSeats > 1 ? 's' : '')} disponible${(availableSeats > 1 ? 's' : '')}` : 'COMPLET'}
              </div>}
            </div>
          </div>

          <div className='EventCardButtons'>
            <Stack direction='column' alignContent='center' alignItems='center' spacing={1} lineHeight={'0.8rem'}>
              {(!noRides && !!availableSeats) &&
                <Button onClick={() => goToRideBooking()} disabled={(!currentUserIsAdmin && iAmADriver) || ((eventData?.rides?.length || 0) === 0)} variant="contained" color='primary'>Je réserve un Ride</Button>}
              <Button onClick={() => applyAsDriver()} disabled={!currentUserIsAdmin && iAmADriver} variant="contained" color='secondary' >{'Je propose un Ride!'}</Button>
            </Stack>
          </div>
        </div>
      </div>
    </div>
  );
}
