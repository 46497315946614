import { createContext, useCallback, useContext, useState } from "react";
import { Button, Dialog, DialogContent, DialogActions } from '@mui/material';

export interface IConfirmDialogProps {
    content: JSX.Element;
    onConfirm: () => void;
    onCancel: () => void;
    confirmLabel?: string;
    cancelLabel?: string;
}

export interface IDialogContext {
    openConfirmDialog: (dialogProps: IConfirmDialogProps) => void;
}



const DialogContext = createContext<IDialogContext>({} as IDialogContext);

export const DialogProvider = ({ children }) => {

    const [confirmDialogProps, setConfirmDialogProps] = useState<IConfirmDialogProps>({
        content: <></>,
        onConfirm: () => { },
        onCancel: () => { },
        cancelLabel: 'Annuler',
        confirmLabel: 'Oui'
    });
    const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);

    const openConfirmDialog = useCallback((dialogProps: IConfirmDialogProps) => {
        setConfirmDialogProps(dialogProps);
        setConfirmDialogOpen(true);
    }, [setConfirmDialogProps, setConfirmDialogOpen]);

    const confirmDialogConfirmClick = useCallback(() => {
        confirmDialogProps.onConfirm();
        setConfirmDialogOpen(false);
    }, [confirmDialogProps]);

    const confirmDialogCancelClick = useCallback(() => {
        confirmDialogProps.onCancel();
        setConfirmDialogOpen(false);
    }, [confirmDialogProps]);

    const [appContext] = useState<IDialogContext>({ openConfirmDialog })

    return <DialogContext.Provider value={appContext}>
        <Dialog open={confirmDialogOpen}>
            <DialogContent>
                {confirmDialogProps.content}
            </DialogContent>
            <DialogActions>
                <Button variant='contained' color='primary' onClick={() => confirmDialogConfirmClick()}>{confirmDialogProps.confirmLabel}</Button>
                <Button variant='outlined' color='secondary' onClick={() => confirmDialogCancelClick()}>{confirmDialogProps.cancelLabel}</Button>
            </DialogActions>
        </Dialog >
        {children}
    </DialogContext.Provider >
}

export const useDialogs = () => {
    const { openConfirmDialog } = useContext(DialogContext);

    const confirm = (content: JSX.Element, confirmLabel?: string, cancelLabel?: string, rejectOnCancel: boolean = false) => {
        return new Promise<boolean>((resolve, reject) => {
            openConfirmDialog({
                content,
                confirmLabel,
                cancelLabel,
                onCancel: rejectOnCancel ? () => reject('') : () => resolve(false),
                onConfirm: () => resolve(true)
            })
        });
    };

    return { confirm };
}