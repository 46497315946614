import { Stack, TextField, FormLabel } from "@mui/material";
import { ILocation } from "../../models/ILocation";
import { useCallback } from "react";

export interface ILocationPickerProps {
    label?: string;
    location: ILocation;
    onLocationChanged: (location: ILocation) => Promise<void>;
}

export const LocationPicker = ({ label, location, onLocationChanged }: ILocationPickerProps) => {

    const onPropertyChanged = useCallback((property: string, value: unknown) => {
        onLocationChanged({ ...location, [property]: value });
    }, [location, onLocationChanged]);

    return <div className="LocationPicker">
        {label && <>
            <FormLabel>{label}</FormLabel>
            <hr />
        </>}
        <Stack direction={'column'} spacing={1}>
            <TextField label='Nom du lieu' value={location?.name} onChange={ev => onPropertyChanged('name', ev.target.value)} />
            <TextField label='Adresse' multiline rows={3} value={location?.address} onChange={ev => onPropertyChanged('address', ev.target.value)} />
            <TextField label='Commentaire (optionel)' multiline rows={3} value={location?.comments} onChange={ev => onPropertyChanged('comments', ev.target.value)} />
        </Stack>
        <hr />
    </div>;
};