import { createTheme } from '@mui/material/styles';

export const customizeTheme = createTheme({
  typography: {
    fontFamily: 'Poppins, sans-serif',
    fontSize: 14,
    fontWeightRegular: 400,


    body1: {
      fontSize: '14px',
      fontWeight: 400,
      margin: '0.3rem',
    },
    body2: {
      lineHeight: '1.5rem',
      fontWeight: 400,
      margin: '1rem',
    },
    h3: {
      fontSize: '20px',
      fontWeight: 600,
      margin: '1rem',
    },
    h4: {
      fontSize: '18px',
      fontWeight: 600,
      margin: '1rem',
    },
    h5: {
      fontSize: '16px',
      fontWeight: 600,
      margin: '1rem',
    },
    h6: {
      fontSize: '14px',
      fontWeight: 600,
      margin: '1rem',
    },

  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          textTransform: 'none',
          // marginBottom: '0.5rem',
          borderColor: '#7B05FA',
          border: '1px solid #7B05FA',
          // width: '80%',
          // height: '40px',
          height: '2.5rem',
          fontWeight: 500,
          padding: 0,
          paddingLeft: '1.5rem',
          paddingRight: '1.5rem',
          margin: 0,
          "&.Mui-disabled": {
            "backgroundColor": "rgba(123, 5, 250, 0.4)",
            "borderColor": "rgba(123, 5, 250, 0.4)",
            "border": "0",
            "color": "white"
          }
        },
        containedPrimary: {
          backgroundColor: '#7B05FA',
          Color: 'white',
        },
        containedSecondary: {
          backgroundColor: 'white',
          color: '#7B05FA',
          borderColor: '#7B05FA',
        },
        containedWarning: {
          backgroundColor: '#f03d',
          color: 'white',
          borderColor: '#f03d',
        },
        outlinedSecondary: {
          backgroundColor: 'white',
          color: '#7B05FA',
          borderColor: '#7B05FA',
        },
        textPrimary: {
          backgroundColor: '#7B05FA',
          Color: 'white',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          borderColor: '#7B05FA',
          // padding: '0.12rem',
          paddingBottom: 0,
          paddingTop: 0,
          marginBottom: '1rem',
          // width: '90%',
          input:
          {
            BorderColor: '#7B05FA',
          }
        },

      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          minHeight: '2.5rem',
          borderRadius: 4,
          borderColor: '#7B05FA',
        },
      },
    },

    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          // Styles généraux pour les labels de contrôle
          fontSize: 14,
          fontWeight: 400,
          color: 'black',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // Styles pour les TextField avec variante "outlined"
          borderRadius: 8,
          // height: '40px',
          minHeight: '2.5rem',
          fontSize: 14,
          marginLeft: '1rem',
          marginRight: '1rem',
        },
        notchedOutline: {
          // Styles pour la bordure des TextField "outlined"
          borderColor: '#7B05FA',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          // Styles pour le label du champ d'entrée
          color: 'black', // Couleur du texte du label
          fontSize: 14, // Taille de la police
          fontWeight: 400, // Poids de la police
          '&.Mui-focused': {
            // Styles appliqués lorsque le champ est focalisé
            color: '#7B05FA',

          },
          '&.MuiFormLabel-filled': {
            // Styles appliqués lorsque le champ est rempli
            color: 'black',
          },
          '&.MuiFormLabel-asterisk': {
            // Styles appliqués lorsque le champ est requis (étoile)
            color: '#d32f2f',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          // Styles généraux pour le Switch
          width: '56px',
          height: '40px',
          alignItems: 'center',
        },
        switchBase: {
          // Styles pour la partie du Switch qui se déplace
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',


            '& + .MuiSwitch-track': {
              backgroundColor: 'rgba(123, 5, 250, 0.4)',
              opacity: 1,
            },
          },
        },
        thumb: {
          // Styles pour le "pouce" du Switch
          width: 22,
          height: 22,
          color: '#7B05FA',
          boxShadow: 'none',

        },
        track: {
          // Styles pour la partie du Switch qui reste fixe
          borderRadius: 26 / 2,
          backgroundColor: 'rgba(123, 5, 250, 0.4)',
          transition: 'background-color 0.3s',
        },
      },
    },
    MuiStack: {
      styleOverrides: {
        root: {
          lineHeight: 1,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          // borderRadius:'0 0 20px 20px',
          padding: '0.5rem',
          backgroundColor: 'rgba(123, 5, 250)',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
          marginBottom: '2rem',
          Height: '100%',
        },
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          margin: '1.5rem',
        },
      },
    },
  },
});

