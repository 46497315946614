import { Button, Stack, TextField } from "@mui/material";
import { IRide } from "../../models/IRide";
import { useCallback, useEffect, useMemo, useState } from "react";
import { LocationPicker } from "../LocationPicker/LocationPicker";
import { useNavigate, useParams } from "react-router-dom";
import { useUserContext } from "../../contexts/UserContext";
import { useTeamContext } from "../../contexts/TeamContext";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Dayjs from 'dayjs';

export const RideForm = () => {
    const { eventId } = useParams<{ eventId: string }>();
    const { user } = useUserContext();
    const { applyAsDriver, eventsAndRides } = useTeamContext();
    const event = useMemo(() => eventsAndRides.find(e => e.event.id === eventId), [eventId, eventsAndRides]);
    const navigate = useNavigate();
    const [data, setData] = useState<IRide>({
        driverId: null,
        eventId,
        availableSeats: 1,
        name: ``,
        comments: '',
        bookings: [],
        teamId: null,
        id: null
    });

    useEffect(() => {
        if (!user)
            return;
        setData({
            driverId: user.id,
            eventId,
            availableSeats: 1,
            name: `Voiture de ${user.firstName}`,
            comments: '',
            bookings: [],
            teamId: null,
            id: null
        });
    }, [user, setData, eventId]);

    const onPropertyChanged = useCallback((property: string, value: unknown) => {
        setData(prev => ({ ...prev, [property]: value }));
    }, [setData]);

    const onNumberPropertyChanged = useCallback((property: string, value: unknown) => {
        const n = parseInt(value as string);
        let ensuredValue = isNaN(n) ? null : n;
        setData(prev => ({ ...prev, [property]: ensuredValue }));
    }, [setData]);

    const onTimePropertyChanged = useCallback((property: string, value: any) => {
        if (!event?.event)
            return;
        const castedValue = value.$d.toISOString();
        setData(prev => ({ ...prev, [property]: castedValue }));
    }, [setData, event]);

    const saveRide = useCallback(async () => {
        try {
            await applyAsDriver(eventId, data);
            navigate('/');
        } catch (error) {
            // TODO: Show an error message to user
            console.error('The car pool could not be saved');
        }
    }, [applyAsDriver, data, eventId, navigate]);

    const cancel = useCallback(() => {
        setData({} as IRide);
        navigate(-1);
    }, [navigate]);

    return <div className="RideForm">
        <LocalizationProvider dateAdapter={AdapterDayjs}>

            <Stack direction={'column'} spacing={2}>
                <TextField label='Nom du Ride' value={data.name} onChange={ev => onPropertyChanged('name', ev.target.value)} />
                <LocationPicker label="Lieu de départ" location={data.startMeetingPoint} onLocationChanged={async l => onPropertyChanged('startMeetingPoint', l)} />
                <TimePicker label='Heure de départ' value={Dayjs(data.startMeetingTime)} onChange={v => onTimePropertyChanged('startMeetingTime', v)} />
                <LocationPicker label="Lieu de retour" location={data.returnMeetingPoint} onLocationChanged={async l => onPropertyChanged('returnMeetingPoint', l)} />
                <TimePicker label='Heure de retour' value={Dayjs(data.returnMeetingTime)} onChange={v => onTimePropertyChanged('returnMeetingTime', v)} />
                <TextField label='Commentaire' value={data.comments} onChange={ev => onPropertyChanged('comments', ev.target.value)} />
                <TextField label='Nombre de place' value={data.availableSeats} onChange={ev => onNumberPropertyChanged('availableSeats', ev.target.value)} />
                <Button variant="contained" color='primary' onClick={() => saveRide()}>Valider</Button>
                <Button variant="outlined" onClick={() => cancel()}>Annuler</Button>
            </Stack>
        </LocalizationProvider>

    </div>;
};