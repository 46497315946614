import { useCallback, useMemo, useState } from "react";
import { useUserContext } from "../../contexts/UserContext";
import { useMsal } from "@azure/msal-react";
import { Button, Avatar, Stack, TextField } from "@mui/material";
import profileIcon from '../../assets/icons/ProfileIcon.svg';
import { IUser } from "../../models/IUser";

export const Profile = () => {
    const { user, updateUserInfo } = useUserContext();
    const { instance } = useMsal();

    const [userInfo, setUserInfo] = useState<IUser>({ ...user });

    const hasChanges = useMemo(() => userInfo.lastName?.toLowerCase() !== user.lastName?.toLowerCase()
        || userInfo.firstName?.toLowerCase() !== user.firstName?.toLowerCase()
        || userInfo.avatar !== user.avatar, [user, userInfo]);

    const signOut = useCallback(async () => {
        await instance.logoutRedirect();
    }, [instance]);

    const discardChanges = useCallback(() => {
        setUserInfo({ ...user });
    }, [setUserInfo, user]);

    const saveChanges = useCallback(async () => {
        await updateUserInfo(userInfo);
        // TODO : Notify success
    }, [userInfo, updateUserInfo]);

    if (!user?.id)
        return null;

    return <div className="UserProfile">
        <Stack direction='column'>
            <div className="UserInfo">
                <Stack direction='column' alignContent='center' alignItems='center'>
                    <Avatar sx={{ width: '8rem', height: '8rem', marginTop: '1rem' }} src={user.avatar || profileIcon} />
                </Stack>
                <Stack direction='column' alignContent='center' alignItems='center'>
                    <h1 style={{ margin: 0 }}>{user.displayName || `${user.firstName} ${user.lastName}`}</h1>
                    <h3 style={{ margin: 0 }}>Membre de {Object.keys(user.teamMemberships).length} équipes</h3>
                </Stack>

                <h2>Mes informations</h2>
                <Stack direction={'column'} spacing={3}>
                    <TextField label='Adresse e-mail' disabled value={userInfo.emailAddress} />
                    <TextField label='Prénom' value={userInfo.firstName} onChange={ev => setUserInfo(prev => ({ ...prev, firstName: ev.target.value }))} />
                    <TextField label='Nom' value={userInfo.lastName} onChange={ev => setUserInfo(prev => ({ ...prev, lastName: ev.target.value }))} />
                    {hasChanges && <>
                        <Button variant="contained" color='primary' onClick={() => saveChanges()}>Valider</Button>
                        <Button variant="outlined" onClick={() => discardChanges()}>Annuler</Button>
                    </>}
                </Stack>

                <h2>Mes équipes</h2>

                <h2>Mes passagers</h2>
            </div>

            <h2>Session</h2>
            <div style={{ margin: '1rem' }} className="CenterButton">
                <Button onClick={() => signOut()} variant="contained" color="warning">Se deconnecter</Button>
            </div>
        </Stack>
    </div>
};