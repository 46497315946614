import { Button, Switch, Stack, TextField, Divider, FormControlLabel, FormGroup, InputLabel } from '@mui/material';
import { ITeam } from "../../models/ITeam";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ITeamSettings } from '../../models/ITeamSettings';
import { ensureNumber } from '../../helpers/numbers';

export interface ITeamSettingsProps {
    team: ITeam;
    updateTeam: (team: ITeam) => void;
    regenerateJoinCode?: () => Promise<string>;
}


export const TeamSettings = ({ team, updateTeam, regenerateJoinCode }: ITeamSettingsProps) => {
    const callRegenerateJoinCode = useCallback(async () => {
        await regenerateJoinCode();
    }, [regenerateJoinCode]);


    const [editedTeam, setEditedTeam] = useState<ITeam>({ ...team, settings: { ...team.settings } });
    useEffect(() => {
        setEditedTeam({ ...team, settings: { ...team.settings } });
    }, [team]);

    const onTeamSettingsChanged = useCallback((settings: ITeamSettings) => {
        setEditedTeam({ ...editedTeam, settings });
    }, [editedTeam, setEditedTeam]);

    const saveChanges = useCallback(() => {
        updateTeam(editedTeam);
    }, [editedTeam, updateTeam]);

    const hasChanges = useMemo<boolean>(() => editedTeam.name !== team.name
        || editedTeam.settings?.autoApproveJoinRequests !== team.settings?.autoApproveJoinRequests
        || editedTeam.settings?.initialBalance !== team.settings?.initialBalance, [editedTeam, team]);

    return <div className="TeamSettings" >
        <form onSubmit={() => saveChanges()}>
            <Divider />
            <h3>Paramètres généraux</h3>
            <Stack direction='column'>

                <TextField value={editedTeam.name} label="Nom de l'équipe" variant='outlined' onChange={ev => setEditedTeam({ ...team, name: ev.target.value })} />

                <TextField label='Solde de réservations initial' variant='outlined' type='number'
                    value={editedTeam.settings.initialBalance}
                    onChange={ev => onTeamSettingsChanged({
                        ...editedTeam.settings,
                        initialBalance: ensureNumber(ev.target.value)
                    } as ITeamSettings)} />

                <Stack direction='row' alignItems='center' spacing={1} style={{ marginBottom: '0.5rem', marginTop: 0 }}>
                    <FormGroup>
                        <FormControlLabel control={<Switch
                            value={!editedTeam.settings.autoApproveJoinRequests}
                            onChange={ev => onTeamSettingsChanged({
                                ...editedTeam.settings,
                                autoApproveJoinRequests: !ev.target.checked
                            } as ITeamSettings)} />}
                            label={"Les demandes d'adhésion doivent être approuvées"} />
                    </FormGroup>
                </Stack>

                <Stack direction='column' alignItems='center' marginBottom='1rem'>
                    <Button variant='contained' disabled={!hasChanges}>Sauver les modifications</Button>
                </Stack>
            </Stack>
        </form>

        <form onSubmit={() => callRegenerateJoinCode()}>
            <Divider />
            <h3>Paramètres d'accès</h3>
            <Stack direction='row' alignItems='center' alignContent='center' spacing={3}>
                <Stack direction='column'>

                    <InputLabel sx={{ margin: 0 }}>Code d'accès</InputLabel>
                    <TextField defaultValue={team?.settings.joinCode} variant="outlined" sx={{ margin: 0 }} slotProps={{
                        input: {
                            readOnly: true,
                        },
                    }} />
                </Stack>
                <Button variant='contained' color='primary'>Regénérer</Button>
            </Stack>
        </form>

        <div style={{ height: '150px' }}></div>
    </div >
};